import React from "react";
import { Typography } from "antd";
import { ClockCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";
import ScrollDownArrow from "./components/movingArrow";
const { Text, Paragraph, Title } = Typography;

export default function Introduction() {
  return (
    <div className="introduction-banner">
      <Title level={2} style={{ color: "white" }}>
        THE HOTEL ASSOCIATION OF NYC FOUNDATION
      </Title>
      <Title
        level={1}
        style={{
          color: "white",
          fontFamily: "ITC Cheltenham",
          fontSize: "4rem",
        }}
      >
        Red Carpet Hospitality Gala
      </Title>
      <Paragraph className="introduction-text">
        <ClockCircleOutlined /> JW Marriott Essex House, 160 Central Park West
        <br />
        <EnvironmentOutlined /> Monday, April 7, 2025, 6pm-9pm
      </Paragraph>
      <Title level={2} style={{ color: "white" }}>
        HONORING
      </Title>
      <div className="honoree-container">
        <Paragraph className="introduction-text honorees">
          <img src="/img/lindsey.jpg" className="headshot" />
          <Text strong className="introduction-text">
            Lindsey Ueberroth
          </Text>
          CEO <br />
          Preferred Travel Group
        </Paragraph>
        <Paragraph className="introduction-text honorees">
          <img src="/img/Susan.jpg" className="headshot" />
          <Text strong className="introduction-text">
            Susan L. Birnbaum
          </Text>
          President & CEO <br />
          NYC Police Foundation
        </Paragraph>
      </div>
      <ScrollDownArrow />
    </div>
  );
}
