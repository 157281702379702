import React from "react";
import { DownOutlined } from "@ant-design/icons";

export default function ScrollDownArrow() {
  return (
    <div
      style={{
        position: "absolute", // Position the arrow absolutely
        bottom: "20px", // Keep it at the bottom
        left: "50%", // Center horizontally
        transform: "translateX(-50%)", // Center adjustment
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <p
        style={{
          fontSize: "14px",
          color: "#fff",
          marginBottom: "5px",
          animation: "fade-in 2s infinite",
        }}
      >
        Scroll Down
      </p>
      <div
        style={{
          animation: "bounce 2s infinite",
          color: "#fff",
          fontSize: "24px",
        }}
      >
        <DownOutlined />
      </div>
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(10px);
            }
            60% {
              transform: translateY(5px);
            }
          }
          
          @keyframes fade-in {
            0%, 100% {
              opacity: 0.7;
            }
            50% {
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}
