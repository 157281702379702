import React, { useState, useEffect } from "react";
import { Alert, Typography } from "antd";

const { Paragraph } = Typography;

export default function Nav(props) {
  const [isScrolled, setIsScrolled] = useState(false);

  // Track scrolling position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const alertMessage = (
    <Paragraph>
      <Paragraph>
        Please make payment payable to HANYC FOUNDATION, INC.
      </Paragraph>
      <Paragraph>
        For your records, your contribution is tax-deductible to the fullest
        extent permitted by law. HANYC Foundation, Inc. is a 501(c)(3)
        non-profit organization. EIN #83-3283722
      </Paragraph>
      <Paragraph>
        Please contact accounting@hanycfoundation.org with any questions you may
        have in regard to the billing.
      </Paragraph>
    </Paragraph>
  );

  return (
    <div>
      <div>
        <div>
          <a
            href="/"
            style={{
              position: isScrolled ? "fixed" : "relative",
              top: 0,
              left: 0,
              backgroundColor: "white",
              display: isScrolled ? "flex" : "none",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "all 0.3s ease-in-out",
              zIndex: 1000, // Ensures it stays above other content
            }}
          >
            <img
              src={require("./foundationlogo.png")}
              style={{ maxWidth: "180px", margin: "1rem" }}
              alt="Foundation Logo"
            />
          </a>
        </div>
        <div style={{ marginTop: "5px" }}>
          {window.location.pathname === "/back-up" ||
          props.appStatus === "running" ? (
            <Alert message={alertMessage} type="info" />
          ) : null}
        </div>
      </div>

      {props.children}
    </div>
  );
}
